import Axios from 'axios'

import Links from './Links'

const $http = Axios

let apiHost = 'http://localhost:9099'

if (window.location.hostname.endsWith('artpass.id') || true) {
  apiHost = `https://artpass.id`
}

function Api () {
  const handlers = {}

  const on = (event, fn) => {
    if (!handlers[event]) {
      handlers[event] = []
    }
    handlers[event].push(fn)
  }

  const emit = (event, data) => {
    // eslint-disable-next-line
    if (!handlers[event]) {
      return
    }
    for (let i = 0, ii = handlers[event].length; i < ii; i++) {
      handlers[event][i](data)
    }
  }

  $http.interceptors.response.use(undefined, function (err) {
    return new Promise(function () {
      if (err.message === 'Network Error') {
        return emit('disconnected')
      }
      console.log('AXIOS', err.response.status, err.response.data)
      throw err
    })
  })

  const api = {
    host: apiHost,
    $http,
    emit
  }

  return {
    api,
    on,
    links: Links(api)
  }
}

export default Api
