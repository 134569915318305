
const routes = [
  // {
  //   path: '/disconnected',
  //   component: () => import('layouts/MainLayout.vue'),
  //   children: [
  //     { path: '', component: () => import('pages/Disconnected.vue') }
  //   ]
  // },
  {
    path: '/',
    component: () => import('pages/Home.vue')
  },
  {
    path: '/result',
    component: () => import('pages/Result.vue'),
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: ':token_id/disconnected',
        props: true,
        component: () => import('pages/Disconnected.vue')
      },
      {
        path: ':token_id',
        props: true,
        component: () => import('pages/Index.vue'),
        children: [{
          path: '',
          component: () => import('components/LinkWorkflow.vue'),
          props: true
        }],
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
